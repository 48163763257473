import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import { ImageType } from "@/types";
import { isOdd } from "@/utils";
import { HorizontalRule } from "@narrative-software/narrative-web-ui";
import LinkWithArrow from "../shared/LinkWithArrow";

type FeaturesSection = {
  title: string;
  sections: {
    id: string;
    title: string;
    description: string;
    icon?: ImageType;
    image: ImageType;
    ctaText?: string;
    ctaHref?: string;
  }[];
};

const FeaturesSection = ({ title, sections }: FeaturesSection) => {
  return (
    <section className="py-20">
      <div className="max-w-[1260px] px-4 mx-auto">
        <HorizontalRule bottomMargin />
        <h2 className="text-h2 font-semibold leading-compact text-center mb-16">{title}</h2>
        <div className="flex flex-col space-y-16 md:space-y-16">
          {sections.map((section, i) => (
            <div
              key={section.id}
              className={classNames("flex flex-col md:flex-row items-center gap-6 md:gap-[122px]", {
                "md:flex-row-reverse": isOdd(i),
                "mb-[60px]": i < 0,
              })}
            >
              <div className="w-full max-w-[600px] md:w-[50%]">
                <DatoImage
                  priority
                  data={{
                    ...section.image.responsiveImage,
                    sizes: "586px",
                  }}
                  objectFit="cover"
                  layout="responsive"
                />
              </div>

              <div className="w-full max-w-[600px] md:w-[50%]">
                {section?.icon && (
                  <DatoImage
                    priority
                    data={{
                      ...section.icon.responsiveImage,
                      height: 30,
                    }}
                    objectFit="cover"
                    layout="fixed"
                    className="mb-4"
                  />
                )}
                <h3 className="text-h3 font-semibold mb-5">{section.title}</h3>
                <p className="text-lg">{section.description}</p>
                {section?.ctaHref && section?.ctaText && (
                  <p className="mt-4">
                    <LinkWithArrow shouldChill href={section.ctaHref}>
                      {section.ctaText}
                    </LinkWithArrow>
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { FeaturesSection };
