import { Image as DatoImage } from "react-datocms";
import classNames from "classnames";
import { ProductSummary } from "@/components";
import { ImageType } from "@/types";
import { ButtonAppearance, ButtonColour } from "@narrative-software/narrative-web-ui";

type CtaWithImageSection = {
  title: string;
  leadLine?: string;
  description: string;
  image: ImageType;
  alignImage: "left" | "right";
  ctas: { text: string; url: string; buttonColor: ButtonColour; buttonAppearance: ButtonAppearance }[];
  backgroundImage?: ImageType;
  className?: string;
  headingLevel?: "h1" | "h2";
};

const CtaWithImageSection = ({
  title,
  leadLine,
  description,
  image,
  ctas,
  alignImage = "left",
  backgroundImage,
  className,
  headingLevel,
}: CtaWithImageSection) => {
  return (
    <section
      className={classNames(
        "flex flex-col md:flex-row w-ful xl:max-h-[600px] md:min-h-[450px] bg-white",
        {
          "md:flex-row-reverse": alignImage === "left",
        },
        className,
      )}
    >
      <div
        className={classNames("flex flex-1 justify-center relative", {
          "xl:justify-end": alignImage === "right",
          "lg:justify-start": alignImage === "left",
        })}
      >
        {backgroundImage && (
          <DatoImage
            priority
            data={{
              ...backgroundImage.responsiveImage,
              sizes: "(max-width: 768px) 100vw, 50vw",
            }}
            objectFit="cover"
            layout="fill"
            className="absolute top-0 right-0 bottom-0 left-0"
          />
        )}
        <div
          className={classNames("flex relative", {
            "xl:mr-[26%]": alignImage === "right",
            "lg:pl-[13%]": alignImage === "left",
          })}
        >
          <ProductSummary title={title} leadLine={leadLine} ctas={ctas} headingLevel={headingLevel}>
            {description}
          </ProductSummary>
        </div>
      </div>
      <div className="flex-1 flex md:hidden">
        <DatoImage
          priority
          data={{
            ...image.responsiveImage,
            sizes: "(max-width: 768px) 100vw, 1px",
          }}
          objectFit="cover"
          layout="responsive"
        />
      </div>
      <div className="flex-1 hidden md:flex">
        <DatoImage
          priority
          data={{
            ...image.responsiveImage,
            sizes: "(max-width: 768px) 1px, 50vw",
          }}
          objectFit="cover"
          layout="responsive"
        />
      </div>
    </section>
  );
};

export { CtaWithImageSection };
