import { Image as DatoImage } from "react-datocms";
import Link from "next/link";
import { ROUTES } from "@/config";
import { ImageType } from "@/types";
import { ArrowAnchorButton } from "../awards";

type PhotographyAwardsCtaSectionProps = {
  title: string;
  description: string;
  ctaText: string;
  logo: ImageType;
  backgroundImage: ImageType;
  mainImage: ImageType;
};

const PhotographyAwardsCtaSection = ({
  title,
  description,
  ctaText,
  logo,
  backgroundImage,
  mainImage,
}: PhotographyAwardsCtaSectionProps) => {
  return (
    <section className="h-full w-full flex flex-col bg-black relative text-white">
      {/* Mobile main image */}
      <div className="h-0 pb-[70%] relative md:hidden">
        <DatoImage
          priority
          data={{
            ...mainImage.responsiveImage,
            sizes: "(max-width: 768px) 100vw, 50vw",
          }}
          objectFit="cover"
          layout="fill"
          className="absolute top-0 right-0 bottom-0 left-0"
        />
      </div>

      {/* Content */}
      <div className="max-w-[1260px] px-6 mx-auto relative z-10 w-full">
        <div className="md:w-[50%] md:float-right py-20 md:py-32 md:pl-12 lg:pl-18 xl:pl-20 flex flex-col gap-5 items-start relative z-10">
          <DatoImage
            priority
            data={{
              ...logo.responsiveImage,
              sizes: "150px",
            }}
          />
          <h3 className="text-48 leading-tight max-w-[380px]">{title}</h3>
          <p className="max-w-[462px]">{description}</p>
          <Link href={`/${ROUTES.AWARDS.BASE.SLUG}`} passHref>
            <ArrowAnchorButton variant="tertiary" className="mt-2">
              {ctaText}
            </ArrowAnchorButton>
          </Link>
        </div>
        {/* Mobile Background */}
        <DatoImage
          priority
          data={{
            ...backgroundImage.responsiveImage,
            sizes: "(max-width: 768px) 100vw, 50vw",
          }}
          objectFit="contain"
          layout="fill"
          objectPosition="top right"
          className="absolute top-0 right-0 md:hidden"
        />
      </div>

      {/* Desktop Background */}
      <div className="hidden md:grid grid-cols-2 gap-0 absolute top-0 right-0 bottom-0 left-0">
        <div className="relative">
          <DatoImage
            priority
            data={{
              ...mainImage.responsiveImage,
              sizes: "(max-width: 768px) 100vw, 50vw",
            }}
            objectFit="cover"
            objectPosition="40%"
            layout="fill"
            className="absolute top-0 right-0 bottom-0 left-0"
          />
        </div>
        <div className="relative">
          <DatoImage
            priority
            data={{
              ...backgroundImage.responsiveImage,
              sizes: "(max-width: 768px) 100vw, 50vw",
            }}
            objectFit="contain"
            layout="fill"
            objectPosition="top right"
            className="absolute top-0 right-0"
          />
        </div>
      </div>
    </section>
  );
};

export type { PhotographyAwardsCtaSectionProps };
export { PhotographyAwardsCtaSection };
