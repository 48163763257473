import { Image as DatoImage } from "react-datocms";
import { ROUTES } from "@/config";
import { IHomePage } from "@/pages/index";
import { Container } from "@material-ui/core";
import LinkWithArrow from "../shared/LinkWithArrow";

/**
 * @component TextBesideImage - Homepage specific version
 */
const TextBesideImage = ({
  highlightTitle,
  highlightDescription,
  highlightImage,
  highlightBackgroundImage,
}: IHomePage) => {
  return (
    <section className="relative py-5 md:p-0">
      <div className="absolute overflow-hidden top-0 right-0 bottom-0 left-0">
        <DatoImage
          data={highlightBackgroundImage.responsiveImage}
          intersectionMargin="1000px"
          layout="fill"
          objectFit="cover"
        />
      </div>
      <Container>
        <div className="relative md:py-30 grid grid-cols-10 gap-y-10 md:gap-x-10 xl:gap-x-26 items-center justify-center mb-10 md:mb-0">
          <div className="col-span-10 md:col-span-4 lg:col-span-5 xl:col-span-4">
            <h3 className="mb-6 text-h2 md:text-24 lg:text-34 font-semibold leading-tight">{highlightTitle}</h3>
            <p className="mb-7 text-lg sm:text-base">{highlightDescription}</p>
            <p className="mb-2">
              <LinkWithArrow shouldChill href={`/${ROUTES.ABOUT.SLUG}`}>
                Learn About Narrative
              </LinkWithArrow>
            </p>
            <p>
              <LinkWithArrow shouldChill href={`/${ROUTES.BLOG.SLUG}`}>
                Read Our Blog
              </LinkWithArrow>
            </p>
          </div>
          <div className="flex col-span-10 md:col-span-6 lg:col-span-5 xl:col-span-6 row-start-1 md:row-auto">
            <DatoImage intersectionMargin="1000px" data={highlightImage.responsiveImage} />
          </div>
        </div>
      </Container>
    </section>
  );
};

export { TextBesideImage };
